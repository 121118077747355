.question-text {
    color: white;
    font-weight:normal;
    font-size: 32px;
    text-shadow: 2px 2px 4px rgb(0, 0, 0);
}

.question-heading {
    color: white;
    font-weight: bold;
    font-size: 32px;
    text-shadow: 2px 2px 4px rgb(0, 0, 0);
}

.result-text {
    color: white;
    font-weight:400;
    font-size: 24px;
    text-shadow: 2px 2px 4px rgb(0, 0, 0);
    text-align: center;
}

.choices-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
}

.result-container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
}

.quiz-footer-button {
    display: flex;
    justify-content: flex-end;
}

.card-footer {
    margin-bottom: 10px;
    margin-right: 20px;
}

.card.quiz-card {
    background-color: transparent;
}

.card-header {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
}

.choice-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.choice-buttons button {
    color: #047690 !important;
    border: 2px solid #047690 !important;
    padding: 5px 15px !important;
    border-radius: 50px !important;
    margin-top: 20px !important;
    width: 400px !important;
    height: 50px !important;
    font-size: 20px !important;
    font-weight: 500 !important;
    z-index: 10;
    transform: translate(-35%, -50%);
    background-color: rgba(245, 245, 245, 0.95);
    font-weight: bolder !important;
}

.choice-buttons button:hover {
    background-color: #ddd !important;
}

.choice-buttons button:disabled {
    color: #606060 !important;
    /* Gray color for text */
    border-color: #a0a0a0 !important;
    /* Gray color for border */
    background-color: #e0e0e0 !important;
    /* Light gray background for disabled state */
    cursor: not-allowed;
    /* Change cursor to indicate that the button is not clickable */
    opacity: 0.9;
    /* Reduce opacity to indicate disabled state */
}

@media (max-width: 1300px) {
    .choice-caption {
        bottom: 5%;
        /* Adjust for smaller screens */
        right: 2%;
    }

    .choice-buttons button {
        font-size: 18px !important;
        /* Adjust font size */
        padding: 6px 12px !important;
        /* Adjust padding */

    }
}

.start-button {
    color: #047690 !important;
    border: 2px solid #047690 !important;
    padding: 5px 15px !important;
    border-radius: 50px !important;
    margin-top: 20px !important;
    width: 300px !important;
    height: 50px !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    z-index: 10;
    background-color: rgba(245, 245, 245, 0.9) !important;
    font-weight: bolder !important;
}

.btn.btn-outline-light.btn-primary.story {
    background-color: rgba(6, 177, 229, 0.9) !important;
}

.centered-question {
    text-align: center;
    margin-bottom: 20px;
    /* Add some space between the heading and the buttons */
    color: white;
    font-weight: bold;
    font-size: 24px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    /* Shadow for better visibility */
}

.story-text {
    color: white;
    font-weight: bold;
    font-size: 24px;
    text-shadow: 2px 2px 4px rgb(0, 0, 0);
}

.story {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .story-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-left: 40px !important;
  }