.description-container {
    margin-top: 20px;
    text-align: center;
}

.description {
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

.mycards ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.mycards li {
    width: 200px;
    height: 300px;
    margin-right: 10px;
    cursor: pointer;
    transition: transform 0.5s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
}

.mycards img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.stacked-cards li .card-description {
    display: none;
}

.active-card-info {
    text-align: center;
    margin-top: 20px;
}

.active-card-info .card-details-container {
    background-color: rgb(162, 238, 255, 0.3);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    margin: 0 auto;
    justify-content: center;
}

.active-card-info .btn-primary {
    background-color: #3498db;
    color: white;
    margin-top: 10px;
    padding: 10px 20px;
    border-radius: 5px;
}

.book-heading {
    font-size: 2em;
    color: rgb(134, 239, 255);
    text-align: center;
    margin-bottom: 10px;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.book-text {
    font-size: 1.5em;
    color: white;
    text-align: center;
    margin-bottom: 0px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.btn-outline-light.book {
    position: relative !important;
    color: #047690 !important;
    background-color: rgb(243, 243, 243, 0.7) !important;
    border: 2px solid #047690 !important;
    padding: 5px 15px !important;
    border-radius: 50px !important;
    margin-top: 20px !important;
    width: 200px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out;
}

.btn-outline-light.book:hover {
    background-color: aliceblue !important;
}

.tooltip.external {
    visibility: hidden;
    background-color: #dadada;
    color: #0086ab;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    bottom: 120%;
    left: 90%;
    transform: translateX(-50%);
    z-index: 1;
    width: max-content;
    font-size: 12px;
  }
  
  .tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
  }

.btn-outline-light.book:hover .tooltip{
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.3s ease-in-out;
}

.btn-group .btn {
    color: white;
    background-color: transparent;
    border: 2px solid #047690;
    padding: 5px 40px;
    margin: 2px;
    font-size: 20px;
    font-weight: 600;
    transition:
        color 0.3s ease-in-out,
        background-color 0.3s ease-in-out,
        border-color 0.3s ease-in-out,
        box-shadow 0.3s ease-in-out,
        transform 0.3s ease-in-out;
}

.btn-group .btn:hover {
    background-color: #00a4c9;
    border-color: #047690;
    color: white;
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.6);
}

.btn-group .btn.active,
.btn-group .btn:focus {
    background-color: #00b0d7 !important;
    border-color: #047690 !important;
    color: white !important;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
    transform: scale(1.1);
}

.btn.btn-outline-light.long{
    width: 195px !important;
}