.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.header-navbar {
  background-color: #f3f3f3;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* 
.nav-button {
  color: #007bff; 
  background-color: transparent;
  border: 1px solid #007bff;
  border-radius: 5px;
  padding: 5px 15px;
  margin: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.nav-button:hover {
  background-color: #007bff;
  color: #ffffff;
} */

main {
  background-image: url('background.png');
  background-size: auto;
  background-repeat: repeat;
  z-index: 1;
}

footer {
  background-image: url('background.png');
  background-size: auto;
  background-repeat: repeat;
  z-index: 1;
  height: 100%;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Gill Sans', Arial, 'Helvetica Neue', Helvetica, sans-serif !important;
  box-sizing: border-box;
  color: #ffffff !important;
}

.btn-outline-light {
  color: #f3f3f3 !important;
  border: 2px solid #047690 !important;
  padding: 5px 15px !important;
  border-radius: 50px !important;
  margin-left: 20px !important;
  width: 165px !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}

.btn.btn-outline-light.dropdown-toggle.btn-primary {
  background-color: transparent !important;
}

.btn-outline-light:hover {
  color: #047690 !important;
}

p {
  font-size: larger;
}

ul {
  font-size: larger;
}

.btn.active {
  background-color: white;
  color: lightseagreen !important;
}

/* .btn{
  border-radius: 50px !important;
} */

.dropdown-menu {
  background-color: transparent !important;
  border: none !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
  padding-right: 20px !important;
  text-align: center !important;
}

.btn-outline-light.dropdown-item {
  background-color: #042D59 !important;
  margin-bottom: 5px !important;
  width: 195px !important;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 15px;
  font-size: 16px;
  background-color: #047690;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, transform 0.3s ease-in-out;
  z-index: 2;
}

.back-to-top.visible {
  opacity: 1;
  visibility: visible;
}

.back-to-top:hover {
  background-color: #00a4c9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transform: scale(1.1);
}


