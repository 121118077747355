
#marine-container {
    width: 100%;
    overflow: hidden;
    z-index: 1;
    min-height: 100vh;
}

#video-container {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    margin-bottom: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

#intro-video {
    width: 80%;
    height: auto;
    object-fit: cover;
    margin: 20px;
    border: 5px solid #ccc;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

#video-control {
    position: absolute;
    bottom: 40px;
    left: 85%;
    transform: translateX(-50%);
    background-color: rgba(7, 233, 37, 0.5);
    color: white;
    border: none;
    cursor: pointer;
    z-index: 2;
    font-size: 60px;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-container {
    flex: 1; /* Takes up 1 part of the flex container */
    padding: 20px;
    max-width: 40%; /* Adjusts the maximum width of the text container */
  }

  #op-background-container {
    flex: 2; /* Takes up 1 part of the flex container */
    position: relative;
    width: 60%; /* Now takes up 60% of the parent container */
    min-height: 50vh;
    margin-top: 20px;
    padding: 0;
    overflow: hidden;
    z-index: 1;
  }
  
  #op-background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1; /* Ensures the video stays behind all other content */
    border: 5px solid #ccc;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }

/* .section-heading {
    font-size: 2.5em;
    color: white;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding-left: 1rem;
    padding-right: 1rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
} */


.section-heading {
    font-size: 2.5em;
    color: white;
    text-align: center;
    margin-bottom: 20px;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.section-text {
    font-size: 1.5em;
    color: white;
    text-align: center;
    margin-bottom: 20px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.game-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    padding: 20px;
}

.gif-wrapper {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.bin-area {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items:end;
    background-color: transparent;
    margin-top: 8rem;
}

.bin {
    width: 70%;
    height: auto;
    cursor: pointer;
}

.debris-area {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-end;
    background-color: transparent;
    padding-left: 20px;
    position: relative;
    margin-top: 10em;
}

.debris-item {
    width: 70px;
    height: 70px;
    margin: 0px;
    background-color: transparent;
    text-align: center;
    cursor: grab;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

.debris-item img {
    max-width: 160%;
    max-height: 160%;
}

@media (max-width: 600px) {
    .debris-item {
        width: 50px; /* Smaller size for smaller screens */
        height: 50px;
    }
    .debris-item img {
        max-width: 100%; /* Adjust image scaling */
        max-height: 100%;
    }
}

.debris-item:active {
    cursor: grabbing;
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 2px solid #47a6ea;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 500px;
}

.popup-content {
    text-align: center;
    font-size: 1.1em;
    color: #043f6a;
    line-height: 1.5;
}


.popup-content img {
    max-width: 50%;
    height: auto;
    margin: 10px;
}

.popup button {
    margin-top: 10px;
    /* padding: 10px 20px; */
    /* color: white; */
    border: none;
    cursor: pointer;
}

.popup button:hover {
    background-color: #388e3c;
}

.reset-button {
    position: absolute;
    bottom: 20px; /* Distance from the bottom of the container */
    left: 50%; /* Position the button horizontally in the center */
    transform: translateX(-50%); /* Adjust for the button's width to truly center it */
    padding: 0; /* Remove padding around the image */
    background-color: transparent; /* Make the button background transparent */
    border: none; /* Remove the border */
    cursor: pointer;
    z-index: 2;
}

.reset-button img {
    width: 50px; /* Adjust the size as needed */
    height: auto;
    display: block; /* Ensure no extra space around the image */
}

.reset-button:hover {
    background-color: transparent; /* Ensure hover doesn't change the background */
}

.arrow-container{
    display: flex;
    justify-content: center;
    align-items: center;
}


.arrow{
    display: flex;
    width: 20em;
    margin-top: 0;
    
}

.congrats-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.9); /* Slightly transparent white background */
    border: 2px solid #4CAF50;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    margin-bottom: 2rem;
}

.congrats-message h2 {
    font-size: 2em;
    color: #4CAF50;
    margin-bottom: 20px;
}

.congrats-message p {
    font-size: 1.2em;
    color: #333;
}

.metal{
    height: 8rem;
}

.pest-game-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    position: relative;
    padding: 20px;

}

.pest-area {
    position: relative;
    width: 85%; 
    height: 500px;
    overflow: visible;
}

.pest-item {
    position: absolute;
    width: 100px;
    height:100px;
    position: absolute;
    background-color: transparent;
    cursor: grab;
}

.pest-item img {
    max-width: 160%;
    max-height: 160%;
}

@media (max-width: 1200px) {
    .pest-item {
        width: 50px;
        height: 50px;
    }
    .pest-item img {
        max-width: 130%; 
        max-height: 130%;
    }
}

.pest-item:active {
    cursor: grabbing;
}

.net{
    width: 12rem;
    height: 12rem;
}

.net-area {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    right: 40px;
    padding: 20px;
    bottom: 300px;
}


.fishing-net {
    width: 70px;
    height: auto;
    cursor: pointer;
}

#intro-container {
    /* background-color: rgba(0, 174, 239, 0.3);  */
    padding: 20px;
    border-radius: 10px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    /* margin: 20px auto; */
    width: 95%;
    text-align: center;
}

#section-container {
    background-color: rgba(0, 174, 239, 0.3); /* Light blue background for a water-themed look */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    width: 95%;
    text-align: center;
    z-index: -1;
    display: flex;
    justify-content: space-between; /* Distributes space between the two main divs */
    align-items: start; /* Aligns items at the start of the container */
}

.pollution-intro-heading {
    color: #005582; /* Dark blue for heading to stand out */
    font-size: 24px;
}

.pollution-intro-text {
    color: #003d5b;
    font-size: 18px;
    margin-top: 10px;
    line-height: 1.5; /* Improved readability for young readers */
}

.pollution-intro-images img {
    width: 100px; /* Small, manageable size for icons or images */
    margin: 10px; /* Spacing around images */
    vertical-align: middle; /* Align images nicely with any inline or nearby text */
}

.learn-more-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px; /* Larger font for easy clicking */
    margin-top: 20px;
}

.learn-more-button:hover {
    background-color: #0056b3; /* Darker blue on hover for interactive feedback */
}


.pollution-icon{
    cursor: 'pointer';
    width: auto;
    max-width: 70%;
    height: auto;
    display: block;
    margin: 0 auto;
}

.pollution-detail{
    width: auto;
    max-width: 80%;
    height: auto;
    display: block;
    margin: 0 auto;
}



.subtype-detail img {
    max-width: 65%; /* Adjust width as necessary */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 10px;
}

.subtype-detail h5 {
    margin-top: 0; /* Tighten spacing between image and header */
}

.flex-item {
    flex: 1 1 45%; /* Adjusts to take up nearly half the width of the container */
    max-width: 45%; /* Ensures items don't exceed 45% width */
    box-sizing: border-box;
}

@media (max-width: 768px) {
    .flex-item {
        flex: 1 1 100%; /* On smaller screens, items will stack */
        max-width: 100%;
    }
}

/* Define the animation keyframes for flipping */
@keyframes flipInY {
    from {
        transform: perspective(400px) rotateY(90deg);
        opacity: 0;
    }
    to {
        transform: perspective(400px) rotateY(0);
        opacity: 1;
    }
}

/* Flip out animation */
@keyframes flipOutY {
    from {
        transform: perspective(400px) rotateY(0deg);
        opacity: 1;
    }
    to {
        transform: perspective(400px) rotateY(-90deg);
        opacity: 0;
    }
}

/* Applied to the pollution-detail when it is active */
.pollution-detail-active {
    animation: flipInY 0.6s both;
}

.pollution-detail-inactive {
    animation: flipOutY 0.6s both;
}

.btn-outline-light.subsection{
    color: #f3f3f3 !important;
    border: 2px solid #047690 !important;
    padding: 5px 15px !important;
    border-radius: 50px !important;
    margin-top: 20px !important;
    width: 200px !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out;
}

.btn-outline-light.subsection:hover{
    color: #047690 !important;
}

@media (max-width: 1000px) {
    .btn-outline-light.subsection{
        width: 150px !important;
    }
}


