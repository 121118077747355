.page {
  width: 80%;
  height: 80%;
  text-align: center;
  user-select: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
}

.page img {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}

.stf__parent{
  max-height: 600px !important;
}