.timeline-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; /* Ensure it takes the full width */
    padding: 20px; /* Add some padding for smaller screens */
    box-sizing: border-box; /* Include padding in width calculation */
  }
  
  .slider-container {
    width: 100%;
    max-width: 600px; /* Maintain fixed length for larger screens */
    margin: 20px 0;
    position: relative;
  }
  
  .slider {
    width: 100%;
    margin-bottom: 10px;
    height: 8px; /* Thicker slider */
    appearance: none;
    background-color: #d3d3d3;
    border-radius: 5px;
    outline: none;
  }
  
  .slider::-webkit-slider-thumb {
    width: 20px;
    height: 20px;
    background-color: #5bc0de;
    border-radius: 50%;
    cursor: pointer;
    appearance: none;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  }
  
  .slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background-color: #5bc0de;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  }
  
  .slider-labels {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 25px;
    left: 0;
    width: 100%;
  }
  
  .slider-label {
    font-size: 0.8rem;
  }
  
  .slider-label.active {
    font-weight: bold;
    color: #5bc0de;
  }
  
  .population-info {
    text-align: center;
  }
  
  .population-icons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  
  .population-icon {
    width: 30px;
    margin: 2px;
  }
  