.video-header-container {
  position: relative;
  margin-bottom: 2rem;
}

.video-container {
  position: relative;
  width: 100%;
  margin-top: 10px;
  display: inline-block;
}

.video-background {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.navbar-transparent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

/* Ensure the rest of the content flows under the video */
.container-fluid {
  position: relative;
  z-index: 2;
  width: 100%;
  clear: both;
}

.card{
  opacity: 85%;
}

.go-button {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  width: auto;
}

button img {
  display: block;
  width: 30%;
  height: auto;
  transition: filter 0.3s ease;
}

button {
  cursor: pointer;
}

button img:hover {
  filter: brightness(80%) contrast(120%) saturate(150%);
}

.btn.btn-outline-light.special {
  height: 70px;
  font-weight: bold !important;
  width: 400px !important;
  font-size: 32px !important;
  margin: 0 !important;
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  background-color: rgba(245, 245, 245, 0.8);
  color: #0588a5 !important;
}

.btn.btn-outline-light.special:hover{
  color: #02b5de !important;
  background-color: whitesmoke !important;
}

.break
{
  margin-top: 40px;
}


