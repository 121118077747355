.carousel-container {
    position: relative;
    flex: 3;
    width: 60%;
    min-height: 50vh;
    margin-top: 20px;
    padding: 0;
    overflow: hidden;
    z-index: 1;
}

.carousel-item {
    position: relative;
    border: 5px solid #ccc;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.carousel-caption {
    top: 60%;
    right: 10% !important;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
}

.carousel-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.carousel-buttons button {
    color: #047690 !important;
    border: 2px solid #047690 !important;
    padding: 5px 15px !important;
    border-radius: 50px !important;
    margin-top: 20px !important;
    width: 300px !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    transform: translate(-50%, -50%);
    z-index: 10;
    background-color: rgba(245, 245, 245, 0.8);
    font-weight: bolder !important;
}

.carousel-buttons button:hover {
    background-color: #ddd;
}

@media (max-width: 1300px) {
    .carousel-caption {
        bottom: 5%;
        /* Adjust for smaller screens */
        right: 2%;
    }

    .carousel-buttons button {
        font-size: 14px !important;
        /* Adjust font size */
        padding: 6px 12px !important;
        /* Adjust padding */
        width: 90% !important;
        /* Use more percentage on smaller screens */
    }
}

@media (max-width: 900px) {
    .carousel-caption {
        bottom: 5%;
        right: 2%;
    }

    .carousel-buttons button {
        font-size: 12px !important;
        /* Further reduce font size */
        padding: 4px 8px !important;
        /* Further adjust padding */
        width: 100% !important;
        /* Make buttons full width */
    }
}


.carousel-control-prev-icon,
.carousel-control-next-icon {
    width: 4rem !important;
    height: 4rem !important;

}

.carousel-control-next,
.carousel-control-prev {
    opacity: 0.8 !important;
}

#section-container-book {
    background-color: rgba(0, 174, 239, 0.3);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 18px auto;
    width: 95%;
    text-align: center;
    z-index: 1;
    /* Remove or set a positive value */
    display: flex;
    justify-content: center;
    /* Center content horizontally */
    align-items: center;
}

.flipbook-wrapper {
    width: 100%;
    max-width: 1000px;
    /* Max width of the flipbook */
    margin: 0 auto;
    /* Centering the flipbook */
    position: relative;
    justify-content: center;
    align-items: center;
}

.flipbook-wrapper>* {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flipbook-wrapper audio {
    width: 100%;
    /* Makes the audio control responsive */
    margin-top: 10px;
    z-index: 1000;
}

.audio {
    margin-bottom: 20px;
}

.story-sec{
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.option{
    display: flex;
    justify-content: center;
}

.sub-option{
    flex-direction: row;
    justify-content: center;
}