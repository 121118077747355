/* #marine-container {
    width: 100%;
    overflow: hidden;
    z-index: 2;
}

#video-container {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    margin-bottom: 0;
}

#intro-video {
    width: 100%;
    height: auto;
    object-fit: cover;
} */

.video-summary-button {
    position: absolute;
    top: 7%;
    left: 87%;
    transform: translateX(-50%);
    color: rgba(0, 225, 255, 0.795);
    border: none;
    cursor: pointer;
    z-index: 2;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tooltip-video {
    visibility: hidden;
    width: 120px;
    background-color: white;
    color: #1b7fb9;
    text-align: center;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 110%;
    left: 100%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip-video::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 20%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: white transparent transparent transparent;
}

.video-summary-button:hover .tooltip-video {
    visibility: visible;
    opacity: 1;
}

.video-summary-button:hover .tooltip-video {
    visibility: visible;
    opacity: 1;
}

#background-container {
    position: relative;
    background: url('../../public/background/species_background.png') no-repeat center center/cover;
    min-height: 80vh;
    /* Ensures the background container takes at least the full height */
    margin-top: 0;
    /* Remove any potential margin that might create a gap */
    padding: 20px;
    overflow: hidden;
    border: 5px solid #ccc;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 80%;
    /* Adjust width as necessary */
    margin: 20px auto;
    box-sizing: border-box;
}

.animal-icon {
    position: absolute;
    height: auto;
    cursor: pointer;
    z-index: 3;
    animation: float 4s ease-in-out infinite;
}

/* Media Queries for Smaller Screens */
/* For iPad and similar tablet sizes */
@media (max-width: 1200px) {
    .animal-icon {
        width: calc(var(--size) * 0.7);
        max-width: 20vh;
    }
}

/* For phone sizes */
@media (max-width: 1000px) {
    .animal-icon {
        width: calc(var(--size) * 0.5);
        max-width: 15vh;
    }
}


/* Define the floating animation */
@keyframes float {
    0% {
        transform: translateY(0);
        /* Start at the original position */
    }

    50% {
        transform: translateY(-10px);
        /* Float slightly up */
    }

    100% {
        transform: translateY(0);
        /* Return to the original position */
    }
}

/* Example of randomizing animations for variety */
.animal-icon:nth-child(odd) {
    animation-duration: 5s;
    /* Slightly different timing for variety */
}

.animal-icon:nth-child(even) {
    animation-duration: 3s;
    /* Different timing for more dynamic effect */
}

.row {
    width: 100%;
}

.species-heading {
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    color: #1c045f;
}


.popup-species {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 900px;
    max-height: 90%;
    background-color: white;
    padding: 20px;
    border: 1px solid #0d08a4;
    z-index: 1000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    overflow-y: auto;
}

.popup-content-species {
    display: flex;
    flex-direction: column;
    color: #043f6a;
    align-items: center;
    line-height: 1.5;
    justify-content: center;
    padding: 20px;
    overflow-y: auto;
    height: calc(100% - 40px);
}

.id {
    max-width: 250px !important;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
}

.habitat {
    max-width: 100%;
}

.popup-content p {
    margin: 5px;
    font-size: large;
    color: #043f6a;
}

.close-button {
    position: relative;
    bottom: 10px;
    transform: translateX(-50%);
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
    z-index: 1001;
}

.close-button:hover {
    color: #000;
}

.close {
    margin-top: 10px !important;
    /* padding: 10px 20px; */
    /* color: white; */
    border: none !important;
    cursor: pointer;
}