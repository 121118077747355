.quiz-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    color: #0f6296 !important;
}

.modal {
    color: #0f6296 !important;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-items: center;
}

.modal-footer {
    justify-content: space-between !important;
}

.quiz-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 60%;
    max-width: 600px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

img.quiz-image {
    width: 100%;
}

.quiz-question {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
}


.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.quiz-options button {
    display: block;
    margin: 10px 0;
    padding: 10px;
    background: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    width: 60%;
}

.navigation-buttons {
    display: flex;
    justify-content: space-between;
}

.navigation-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.navigation-buttons button:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.btn.btn-secondary {
    color: #f3f3f3 !important;
    border: 2px solid #047690 !important;
    padding: 5px 15px !important;
    border-radius: 50px !important;
    background-color: #0f6296;
    height: 4rem;
    font-size: medium;
}

.btn.btn-success {
    height: 4rem;
}

.btn.btn-danger {
    height: 4rem;
}

.btn-outline-light.quiz {
    color: #047690 !important;
    border: 2px solid #047690 !important;
    padding: 5px 15px !important;
    border-radius: 50px !important;
    margin-top: 20px !important;
    width: 150px !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    transition: background-color 0.3s, color 0.3s;
    background-color: transparent !important;
}

.btn-outline-light.quiz:disabled {
    color: #a0a0a0 !important;
    /* Gray color for text */
    border-color: #a0a0a0 !important;
    /* Gray color for border */
    background-color: #e0e0e0 !important;
    /* Light gray background for disabled state */
    cursor: not-allowed;
    /* Change cursor to indicate that the button is not clickable */
    opacity: 0.6;
    /* Reduce opacity to indicate disabled state */
}

.btn-outline-light.quiz:hover:not(:disabled) {
    background-color: #047690 !important;
    color: white !important;
}