.slider-container-health {
  width: 90%;
  margin: 20px 0;
  position: relative;
}

.slider {
  appearance: none;
  width: 100%;
  height: 8px;
  background: linear-gradient(to right, #acebfb, #00a8ce);
  border-radius: 10px;
  outline: none;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 60px; 
  height: 60px;
  background: url('fish-slider-icon.png') no-repeat center; /* Fish icon as the slider thumb */
  background-size: contain;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 60px;
  height: 60px;
  background: url('fish-slider-icon.png') no-repeat center;
  background-size: contain;
  cursor: pointer;
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 25px;
  left: 0;
  width: 100%;
  font-size: 0.75rem;
}

.slider-label {
  font-size: 0.8rem;
  flex: 1;
  text-align: center;
}

.slider-label.active {
  font-weight: bold;
  color: #5bc0de;
}

.ocean-health-info {
  text-align: center;
}

.ocean-health-info p {
  margin: 5px 0;
}

.ocean-health-info strong {
  font-weight: bold;
}

.health-text {
  flex-grow: 1;
}

.health-image {
  margin-left: 40px;
}

.health-fish {
  width: 90%;
  height: 90%;
}

.star {
  font-size: 1.8rem;
  margin: 0 2px;
}

.star.filled {
  color: #f1c40f;
}

.star.empty {
  color: #d3d3d3;
}

.btn.btn-secondary.info-button {
  position: relative;
  background-color: transparent !important;
  border: none !important;
  cursor: pointer;
  padding: 2px !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  transform: translateY(-10px);
}

.info-button svg {
  fill: #61d3fc;
}

.info-button:hover svg {
  fill: #378de9;
}

.tooltip {
  visibility: hidden;
  width: 90px;
  background-color: white;
  color: #46b9fc;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  padding-left: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip on hover */
.info-button:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

/* Show the tooltip on hover */
.info-button:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.marine-threats .threat {
  display: flex;
  align-items: center;
  margin: 20px
}

.threats-img {
  width: 80%;
  height: 80%;
  margin-right: 20px;
  object-fit: cover;
}

.marine-threats .threat h1 {
  margin-bottom: 50px;
  color: #9ae7ff;

}

.marine-threats .threat h4 {
  margin: 0;
  color: #9ae7ff;
}

.climate-img {
  width: 15vw;
  height: 15vw;
  border-radius: 50%;
  object-fit: cover;
}

.coral-temperature-slider {
  margin-top: 20px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.coral-temperature-slider img {
  width: 90%;
  height: auto;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 2%;
}

.coral-temperature-slider input[type="range"] {
  width: 80%;
}

.coral-temperature-slider .slider-labels {
  position: relative;
  width: 100%;
  margin: 10px 0;
}

.coral-temperature-slider .label {
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  /* Centers the label over the tick */
}


.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.flip-card {
  background-color: transparent;
  width: 12vw;
  height: 12vw;
  perspective: 1000px;
  border-radius: 10px;
  overflow: hidden;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
}

.flip-card-back {
  background-color: #2a73a8;
  color: white;
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}

.flip-card-back p {
  font-size: medium;
}

@media (width <= 1400px) {
  .flip-card-back p{
    font-size: small;
  }
  .marine-threats .threat h4 {
    font-size: 1rem;
}
}

.drill {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.drill-img {
  width: 11vw;
  height: 11vw;
  border-radius: 50%;
  object-fit: cover;
}

.drill-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  height: 100%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 9999;
}

/* .btn-video{
  color: #047690 !important;
  border: 2px solid #047690 !important;
  padding: 5px 15px !important;
  border-radius: 50px !important;
  margin-left: 20px !important;
  width: auto !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  background-color: whitesmoke;
} */

/* .btn-video:hover {
  color: #00d0ff !important;
} */

.scott {
  display: block;
  width: 50%;
  border-radius: 2%;
}

.image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.btn-video svg {
  color: rgba(255, 255, 255, 0.7);
  animation: pulse 1s infinite;
  transition: color 0.3s ease, box-shadow 0.3s ease;
}

.btn-video:hover svg {
  color: rgb(206, 255, 255);
  animation: none;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.7);
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.name {
  align-items: flex-start !important;
}