.map-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.australia-map {
    transform: scale(1.5);
    transform-origin: center;
    max-width: 800px;
    height: auto;
}

@media (max-width: 1200px) {
    .australia-map {
        transform: scale(1.3);
    }
}

@media (max-width: 768px) {
    .australia-map {
        transform: scale(1.1);
    }
}

@media (max-width: 480px) {
    .australia-map {
        transform: scale(0.9);
    }
}


.australia-map path {
    cursor: pointer;
    transition: fill 0.3s, filter 0.3s;

}

.australia-map path:hover {
    fill: rgba(255, 255, 255, 1);
    filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.7));
}


.state-name {
    font-weight: bold;
    font-size: 16px;
    fill: #434343;
    font-family: cursive !important;
}

.species-section,
.habitats-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}

.species-images img,
.habitat-images img {
    border-radius: 8px;
    border: 1px solid #ddd;
}

.habitats-section p {
    font-size: 1.5rem;
}

/* Species Flip Cards (Round) */
.map-flip-card {
    background-color: transparent;
    width: 150px;
    height: 150px;
    perspective: 1000px;
    margin: 5px;
    border-radius: 50%;
    /* Make the card round */
}

.map-flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    border-radius: 50%;
}

.map-flip-card:hover .map-flip-card-inner {
    transform: rotateY(180deg);
}

.map-flip-card-front,
.map-flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.map-flip-card-front {
    background-color: #bbb;
}

.map-flip-card-back {
    background-color: #2a73a8;
    color: white;
    transform: rotateY(180deg);
}

.map-flip-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.habitat-flip-card {
    background-color: transparent;
    width: 300px;
    height: 200px;
    perspective: 1000px;
    margin: 5px;
    border-radius: 10px;
}

.habitat-flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    border-radius: 10px;
}

.habitat-flip-card:hover .habitat-flip-card-inner {
    transform: rotateY(180deg);
}

.habitat-flip-card-front,
.habitat-flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.habitat-flip-card-front {
    background-color: #bbb;
}

.habitat-flip-card-back {
    background-color: #2a73a8;
    color: white;
    transform: rotateY(180deg);
}

.habitat-flip-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.tab-content-centered {
    text-align: center;
    padding: 20px;
}

.tab-pane.tab-content-1 {
    border: 5px solid #3498db !important;
    padding: 20px;
    border-radius: 10px;
}

/* General inactive tabs */
.custom-tabs .nav-link {
    background-color: #cccccc !important;
    color: white !important;
}

/* Tab 1 Active Styles */
.custom-tabs .nav-link.active.tab-1 {
    background-color: hsl(204, 70%, 53%) !important;
    color: white !important;
    box-shadow: 0 0 20px 5px rgba(52, 152, 219, 0.8) !important;
    transition: box-shadow 0.3s ease-in-out;
}

.tab-pane.tab-content-2 {
    border: 5px solid #00d2c1 !important;
    padding: 20px;
    border-radius: 10px;
}

.custom-tabs .nav-link.active.tab-2 {
    background-color: rgb(0, 210, 193) !important;
    color: white !important;
    box-shadow: 0 0 20px 5px rgba(0, 210, 193, 0.8) !important;
    transition: box-shadow 0.3s ease-in-out;
}

.aquarium-item {
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
  }
  
  .aquarium-logo {
    max-width: 100%;
    height: auto;
  }
  
  .aquarium-interior {
    max-width: 100%;
    height: auto;
    border-radius: 2%;
  }
  
  .aquarium-details {
    margin-top: 10px;
  }
  
  .aquarium-details h5 {
    margin-bottom: 10px;
  }
  
  .aquarium-details a {
    color: #3498db;
    text-decoration: underline;
  }
  